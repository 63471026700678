@charset "UTF-8";
@import "../../../../../../node_modules/craftcms-sass/mixins";

.cp-chart {
  margin: 0;
  height: 100%;
  position: relative;
}

.cp-chart svg {
  width: 100%;
  height: 100%;
}

.cp-chart .axis path,
.cp-chart .axis line {
  fill: none;
  stroke: $hairlineColor;
  shape-rendering: crispEdges;
}

.cp-chart .ticks-axis path,
.cp-chart .ticks-axis line {
  fill: none;
  stroke: none;
  shape-rendering: crispEdges;
}

.cp-chart .ticks-axis g.tick text {
  fill: $black;
  font-size: 11px;
}

.cp-chart .axis text {
  fill: RGBA(0, 0, 0, .6);
}

.cp-chart .grid-line .tick {
  stroke: RGBA(0, 0, 0, .08);
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}

.cp-chart .grid-line .tick line {
  stroke: RGBA(0, 0, 0, .08);
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}

.cp-chart .grid-line path {
  stroke-width: 0;
}

.tooltip {
  border-radius: 3px;
  padding: 7px 10px;
  background: transparentize($white, 0.1);
  position: absolute;
  z-index: 1000000;
  @include shadow;
  user-select: none;
  pointer-events: none;
}


